import React, { useMemo, useState } from 'react';
import ArrowDownIcon from 'svg/angleDownArrowIcon';

export type CollapsedMenuItemProps = {
  isOpen?: boolean;
  isSelected: boolean;
  path: string;
  Icon?: React.ElementType;
  children?: React.ReactNode;
  title: string;
  onItemClick?: (_path: string) => void;
};

const CollapsedMenuItem = ({
  Icon,
  isSelected,
  isOpen,
  path,
  title,
  children = null,
  onItemClick = (_path: string) => {},
}: CollapsedMenuItemProps) => {
  const BG_STYLE = isSelected ? 'bg-blue-50 h-auto' : 'bg-transparent';

  const ITEM_ICON = useMemo(
    () =>
      Icon && (
        <Icon
          w={'16'}
          h={'16'}
          className={`${isOpen || isSelected ? 'fill-blue-200 stroke-blue-200' : 'fill-primary-350 stroke-primary-400'}`}
        />
      ),
    [isOpen, isSelected],
  );
  return (
    <div
      className={`menu w-8 h-auto ${BG_STYLE} hover:text-blue-200 fill-blue-200 hover:bg-blue-50 flex justify-center rounded-lg cursor-pointer group relative`}>
      <div
        className="w-6 flex flex-col justify-center items-center hover:bg-blue-50 border-none"
        onClick={() => onItemClick(path)}>
        <div className="w-6 flex justify-start hover:fill-blue-200 gap-2 items-center hover:bg-blue-50 relative">
          {ITEM_ICON}
          <span
            className={`${isOpen || isSelected ? 'text-blue-200 font-bold' : 'text-primary-350 font-normal'} text-lg font-primary`}></span>
        </div>
        {children}
      </div>
      <span
        className={`
          absolute left-full top-1/2 -translate-y-1/2 ml-2
          px-2 py-1
          bg-gray-800 text-white
          text-sm rounded
          whitespace-nowrap
          opacity-0 invisible
          group-hover:opacity-100 group-hover:visible
          transition-all duration-300
        `}>
        {title}
      </span>
    </div>
  );
};

export default CollapsedMenuItem;
