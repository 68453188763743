import { SubMenuItemProps } from 'constants/sideBarMenuItems';
import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CollapsedMenuItem from './CollapsedMenuItem';

export type CollapsedSidebarItemProps = {
  title: string;
  Icon?: React.ElementType;
  path: string;
  options?: SubMenuItemProps[];
};

const CollapsedSidebarItem = (props: CollapsedSidebarItemProps) => {
  const { path, Icon, title, options = [] } = props;

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const effectivePath = useMemo(() => {
    if (options.length > 0 && options[0].path) {
      return options[0].path;
    }
    return path;
  }, [options, path]);

  const IS_SELECTED = useMemo(
    () => pathname === effectivePath,
    [effectivePath, pathname],
  );

  const onNavigate = (path: string) => navigate(path);

  return (
    <CollapsedMenuItem
      path={effectivePath}
      Icon={Icon}
      isSelected={IS_SELECTED}
      onItemClick={onNavigate}
      title={title}
    />
  );
};

export default CollapsedSidebarItem;
