import { createContext, FC, useContext, useState } from 'react';

import { animated, config, useSpring } from '@react-spring/web';

import { withAuthGuard } from 'components/hoc';
import logo from 'assets/triple20logo.svg';
import powered_by from 'assets/poweredbyXH.svg';
import isologo from 'assets/XH-isologo.svg';
import LogoutIcon from 'assets/SVG/logOutIcon';
import { sideBarMenuItems } from 'constants/sideBarMenuItems';
import SidebarItem from 'components/primitives/SidebarItem';
import { useLogout } from 'hooks/useLogout';
import CollapsedSidebarItem from 'components/primitives/SideBarCollapsed';
import sidebarOpen from 'assets/SVG/sidebar-show.svg';
import sidebarClose from 'assets/SVG/sidebar-hide.svg';

const STYLES = {
  from: {
    x: -200,
    opacity: 0,
  },
  to: {
    x: 0,
    opacity: 1,
  },
  config: config.default,
};

const SideBar: FC = () => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [selectedElementKey, setSelectedElementKey] = useState<string>();
  const styles = useSpring(STYLES);
  const SidebarContext = createContext(null);
  const openSidebarIcon = sidebarOpen;
  const closeSidebarIcon = sidebarClose;

  const toggleExpandSidebar = () => setIsExpanded((prevState) => !prevState);

  const handleLogout = useLogout();
  return (
    <animated.aside
      className={`${isExpanded ? 'w-64' : 'w-16 items-center'} flex shadow-lg z-50 shadow-gray-300 flex-col  h-screen transition-all duration-100 ease-in-out`}
      style={styles}
      role="navigation">
      <img
        src={isExpanded ? sidebarClose : sidebarOpen}
        alt="sidebar-icon"
        draggable={false}
        className="p-2 m-2 cursor-pointer hover:bg-blue-50 rounded-md w-10"
        onClick={toggleExpandSidebar}
      />
      <div className="flex flex-1 items-center justify-center max-h-[10vw] h-full">
        <img alt="imglogo" src={logo} className="w-48 py-8" draggable={false} />
      </div>

      <ul className="flex flex-col items-center gap-7">
        {[...sideBarMenuItems].map(
          ({ title, path, items, Icon }, idx: number) => {
            const KEY = `menu-item-${title}-${idx}`;
            const IS_SELECTED = KEY === selectedElementKey;

            if (isExpanded) {
              return (
                <SidebarItem
                  key={KEY}
                  path={path ?? ''}
                  title={title}
                  Icon={Icon}
                  options={items}
                />
              );
            } else {
              return (
                <CollapsedSidebarItem
                  key={KEY}
                  path={path ?? ''}
                  title={title}
                  Icon={Icon}
                  options={items}
                />
              );
            }
          },
        )}
        {/* Logout button */}
        <div
          onClick={handleLogout}
          className="flex btn btn-ghost btn-md h-[60px] font-primary text-primary-350 rounded-lg hover:bg-blue-50 hover:text-blue-200 mr-24">
          <LogoutIcon width={25} height={25} />
          {isExpanded && 'Logout'}
        </div>
      </ul>
      <div className="h-[200px] w-full flex flex-col gap-2 items-center justify-center">
        {isExpanded ? (
          <div className="h-[100px] w-[120px]">
            <img alt="poweredBy" src={powered_by} draggable={false} />
          </div>
        ) : (
          <div className="w-6">
            <img alt="logo" src={isologo} draggable={false} />
          </div>
        )}
      </div>
    </animated.aside>
  );
};

export default withAuthGuard(SideBar);
